/* The '!important' is needed to override ChakraUI's default values*/

/* Default body background image for screens with widths >= 768px */
#AppCont{
  background-image: url(../Images/CompletedBackground.png) !important;
  background-repeat: no-repeat !important;
  background-color: white !important;
  background-size: 100% auto !important;
}

/* Remove the body background image for mobile devices */
@media screen and (max-width: 767px) {
    #AppCont{
      background-image: none !important;
    }
}
